.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indexedStep.accomplished {
    background-color: rgba(0, 116, 217, 1);
  }

.progressLabel {
    color: white;
}